import React from "react";

import NoFooterSiteLayout from '../components/site_layout/no_footer_layout';
import SEO from "../components/seo";

import LensManageList from '../components/products/lens_manage_list';

// 镜片管理 列表页面

const LensManageListPage = () => (
  <NoFooterSiteLayout>
    <SEO title="Lens manage" />
    <LensManageList></LensManageList>
  </NoFooterSiteLayout>
)

export default LensManageListPage;
