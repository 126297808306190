import React, { useState } from 'react';
import { Drawer } from 'antd';

import { CustomForm } from '../base_components/form_component';
import {
  lensFormDisabledKeys,
  lensFormNotShowKeys,
  lensFormKeySelectComponentsObj,
  lensFormKeysLabelsObj,
  lensFormInitialObj,
  lensFormGetRequestParamsKeys,
} from './products_columns_labels_config';

// 镜片 request get 请求 条件

const LensGetParamsForm = ({ drawerVisible, closeDrawer, sendQueryRequest }) => {
  const [initFormCount, setInitFormCount] = useState(0);

  const onFinish = (values) => {
    // 提交表单 操作
    let lensQueryParamsObj = {};
    for (const iterator of lensFormGetRequestParamsKeys) {
      if (parseInt(values[iterator]) > 0 || typeof(values[iterator]) === 'string') {
        lensQueryParamsObj[iterator] = values[iterator];
      }
    }
    sendQueryRequest(lensQueryParamsObj);
  };

  const cancelHandle = () => {
    // 关闭抽屉 操作
    setInitFormCount(initFormCount + 1);  // 初始化表单
    closeDrawer();
  }

  return (
    <Drawer
      title="Basic Drawer"
      placement="top"
      closable={false}
      visible={drawerVisible}>
      <CustomForm
        columns={lensFormGetRequestParamsKeys}
        disabledKeys={lensFormDisabledKeys}
        notShowKeys={lensFormNotShowKeys}
        formKeyLabelObj={lensFormKeysLabelsObj}
        formKeySelectComponentsObj={lensFormKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={cancelHandle}
        formInitialObj={lensFormInitialObj}
        initFormCount={initFormCount}
      ></CustomForm>
    </Drawer>
  )
};

export default LensGetParamsForm;
