import React, { useRef, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import ProductsList from '../base_components/products_list';
import LensGetParamsForm from './lens_request_params_form';
import { LensPriceRequestGet } from './products_requests';

// 镜片管理列表

const LensManageList = () => {
  // 镜片管理列表
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [lensGetRequestCount, setLensGetRequestCount] = useState(0);
  const lensGetRequestParams = useRef({ page: 0 });

  const hasMore = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [lensManageLists, setLensManageLists] = useState([]);

  const loadMoreLensList = () => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (isLoading && !hasMore.current) {
      console.log('All loaded...')
      return;
    }
    setIsLoading(true);
    console.log('reach end');
    lensGetRequestParams.current = { ...lensGetRequestParams.current, page: lensGetRequestParams.current.page + 1 }

    setLensGetRequestCount(lensGetRequestCount + 1);
  }

  const judgeResponseSuccess = (respObj) => {
    // 判断response是否成功
    setIsLoading(false);
    const requiredKeysValues = ['code', 'results'];
    const respObjKeys = Object.keys(respObj);
    for (const iterator of requiredKeysValues) {
      if (!respObjKeys.includes(iterator)) {
        return false;
      }
    }
    if (respObj.code !== 1000
      || !Array.isArray(respObj.results)) {
      return false;
    }
    return true;
  }

  const lensGetResponseHandle = (respObj) => {
    // 获取镜片列表后操作
    if (judgeResponseSuccess(respObj)) {
      setLensManageLists(lensManageLists.concat(respObj.results));
      hasMore.current = respObj.next ? true : false;
    } else {
      hasMore.current = true;
      lensGetRequestParams.current = { ...lensGetRequestParams.current, page: lensGetRequestParams.current.page - 1 }
    }
  };

  const sendQueryRequest = (queryParams) => {
    // 发送查询请求
    setLensManageLists([]);
    setDrawerVisible(false);
    lensGetRequestParams.current = { ...lensGetRequestParams.current, ...queryParams };
    loadMoreLensList();
  };

  return (
    <div>
      <Space>
        <Button onClick={() => { setDrawerVisible(true); }} icon={<SearchOutlined />}>搜索</Button>
        <Button onClick={() => { navigate('/lenscreate') }}>新建镜片</Button>
      </Space>
      <LensGetParamsForm drawerVisible={drawerVisible}
        closeDrawer={() => { setDrawerVisible(false); }}
        sendQueryRequest={sendQueryRequest}></LensGetParamsForm>

      <ProductsList productsData={lensManageLists}
        loadMore={loadMoreLensList} loading={isLoading} hasMore={hasMore.current}></ProductsList>

      <LensPriceRequestGet requestCount={lensGetRequestCount}
        reqParams={lensGetRequestParams.current}
        responseDataHandle={lensGetResponseHandle}></LensPriceRequestGet>
    </div>
  )
};

export default LensManageList;
